import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import pic11 from '../assets/images/mills-publishing.jpg';

const AboutUs = (props) => (
  <Layout>
    <Helmet>
      <title>About Us - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Mills Publishing specializes in performing arts playbills, university sports programs, professional journals and periodicals throughout Utah."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>About Us</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <p>
            <h3>Over 30 Publications to Maximize Your Reach!</h3>
            Mills Publishing specializes in performing arts playbills,
            university sports programs, professional journals and periodicals
            throughout Utah.
          </p>
          <p>
            We provide advertising opportunities to businesses and marketing
            professionals who wish to build strong connections and brand
            recognition with their target markets. Packages are designed to
            capitalize your advertising budget across a wide selection of
            publications.
          </p>
          <p>
            <h3>Design And Marketing Services</h3>
            Our talented Art Department provides in-house high quality design
            services including posters, brochures, mailers and more. We also
            provide a full range of design services beyond our publications;
            we’ll help you look your best! Our experienced team can assist with
            strategies to best reach your targets.
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default AboutUs;
